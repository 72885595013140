@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Source+Sans+Pro:wght@400;600;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader-wrapper {
  display: inline-flex;
  margin-left: 2px;
  margin-bottom: 3px;
}
.loader-points {
  display: flex;
  justify-content: space-between;
  width: 16px;
}

.point {
  width: 3px;
  height: 3px;
  border-radius: 50%;
}

.point-1 {
  animation-name: point;
  animation-duration: 0.2s;
  animation-delay: 0.1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

.point-2 {
  animation-name: point;
  animation-duration: 0.2s;
  animation-delay: 0.2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

.point-3 {
  animation-name: point;
  animation-duration: 0.2s;
  animation-delay: 0.3s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

@keyframes point {
  0% {
    transform: translateY(-2px) scaleX(1) scaleY(1);
  }
  100% {
    transform: translateY(0px) scaleX(1.1) scaleY(0.9);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@layer utilities {
  .writerly-custom-scroll::-webkit-scrollbar {
    width: 2px;
  }
  
  .writerly-custom-scroll::-webkit-scrollbar-track {
    background: #f0f1f2;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  .writerly-custom-scroll::-webkit-scrollbar-thumb {
    background: #cfd1d6;
    border-radius: 10px;
  }
  
  .writerly-custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }

  .writerly-custom-zoom {
    zoom: 0.85;
    -moz-transform: scale(0.85);
    -moz-transform-origin: 0 0;
  }

  .writerly-dropdown-scroll::-webkit-scrollbar {
    width: 4px;
  }

  .writerly-dropdown-scroll::-webkit-scrollbar-track {
    background: inherit;
    border-radius: 10px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .writerly-dropdown-scroll::-webkit-scrollbar-thumb {
    background: #cfd1d6;
    border-radius: 10px;
  }

  .writerly-dropdown-scroll::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}